<div class="container-scroller">
  <!-- Navbar -->
  <app-navbar *ngIf="showNavbar"></app-navbar>
  <!-- /Navbar -->

  <div class="container-fluid page-body-wrapper">
    <!-- Sidebar -->
    <app-sidebar *ngIf="showSidebar"></app-sidebar>
    <!-- /Sidebar -->

    <!-- Main panel -->
    <div class="main-panel">
      <!-- Content -->
      <div class="content-wrapper">
        <router-outlet></router-outlet>
      </div>
      <!-- /Content -->

      <!-- Footer -->
      <app-footer *ngIf="showFooter"></app-footer>
      <!-- /Footer -->
    </div>
    <!-- /Main panel -->
  </div>
</div>
