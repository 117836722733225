import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from './service/auth/auth.service';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit{
  title = 'demo1';
  currentUrl:any;
  showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;
  isLoading: boolean = false;

    constructor(
      private router: Router,    
      private authService:AuthService,
      private route:ActivatedRoute,
      private renderer: Renderer2,
       private el: ElementRef) {
      router.events.forEach((event) => {
        if (event instanceof NavigationStart) {
          if (
            event['url'] == '/' ||
            event['url'] == '/login' ||
            event['url'] == '/register' ||
            event['url'] == '/erreur' 
          ) {
            this.showSidebar = false;
            this.showNavbar = false;
            this.showFooter = false;

            const mainPanel = this.el.nativeElement.querySelector('.main-panel');
            const pageBodyWrapper = this.el.nativeElement.querySelector('.page-body-wrapper');
            const contentWrapper = this.el.nativeElement.querySelector('.content-wrapper');

            this.renderer.addClass(mainPanel, 'w-100');
            this.renderer.addClass(pageBodyWrapper, 'full-page-wrapper');
            this.renderer.removeClass(contentWrapper, 'auth');
            this.renderer.removeClass(contentWrapper, 'auth-img-bg');
            this.renderer.removeClass(contentWrapper, 'lock-full-bg');

            if (event['url'] == '/erreur' ) {
              this.renderer.addClass(contentWrapper, 'p-0');
            }
          } else {
            this.showSidebar = true;
            this.showNavbar = true;
            this.showFooter = true;

            const mainPanel = this.el.nativeElement.querySelector('.main-panel');
            const pageBodyWrapper = this.el.nativeElement.querySelector('.page-body-wrapper');
            const contentWrapper = this.el.nativeElement.querySelector('.content-wrapper');

            this.renderer.removeClass(mainPanel, 'w-100');
            this.renderer.removeClass(pageBodyWrapper, 'full-page-wrapper');
            this.renderer.removeClass(contentWrapper, 'auth');
            this.renderer.removeClass(contentWrapper, 'auth-img-bg');
            this.renderer.removeClass(contentWrapper, 'p-0');
          }
        }
      });

      // Spinner for lazyload modules
      router.events.forEach((event) => {
        if (event instanceof RouteConfigLoadStart) {
          this.isLoading = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.isLoading = false;
        }
      });
    }

    ngOnInit() {
      
// Utiliser le service Router pour obtenir l'URL actuelle et réagir aux changements de navigation
this.router.events
.pipe(filter(event => event instanceof NavigationEnd))
.subscribe((event: any) => {
  this.currentUrl = event.urlAfterRedirects;
});

// Alternative: Obtenir l'URL actuelle au moment de l'initialisation du composant
this.currentUrl = this.router.url;
console.log(this.currentUrl);


      




      // Scroll to top after route change
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
    }
  }




