import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';


const routes: Routes = [
  
  
  
  {
    path:'',
    loadChildren: () =>
      import('./views/views.module').then((m) => m.ViewsModule),
  },
];

@NgModule({  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
